<template>
    <div>
        <el-form :model="AddDateModel" ref="AddDateRef" :rules="AddDateRules" label-width="130px">
            <el-form-item label="指标名称：" prop="name">
                <el-input v-model="AddDateModel.name" placeholder="请填写指标名称"></el-input>
            </el-form-item>

            <el-form-item label="指标内涵：" prop="connotation">
                <el-input type="textarea" v-model="AddDateModel.connotation" placeholder="请填写指标内涵"></el-input>
            </el-form-item>

            <el-form-item label="指标状态：" prop="status">
                <el-switch v-model="AddDateModel.status" active-color="#13ce66" inactive-color="#929292" active-value="0" inactive-value="1">
                </el-switch>
            </el-form-item>

            <el-form-item label="指标驱动系数：" prop="drivefactor">
                <el-input type="number" v-model="AddDateModel.drivefactor" placeholder="请填写指标驱动系数"></el-input>
            </el-form-item>

            <el-form-item label="备注：" prop="remark">
                <el-input type="textarea" v-model="AddDateModel.remark" placeholder="请填写备注"></el-input>
            </el-form-item>

            <div class="btnCen">
                <el-button v-if="addDepClick" type="primary" :loading="addDepClickKing" @click="addKeshi">{{diaBtn}}</el-button>
                 <el-button v-else type="info" :loading="addDepClickKing"></el-button>
            </div>

        </el-form>
    </div>
</template>

<script>
import _qs from "qs";
export default {
    props: ["diaBtn", "EditDateModel", "wardId", "listTit"],
    data() {
        return {
            addDepClick: true,
            addDepClickKing: false,
            AddDateModel: {
                name: "",
                connotation: "",
                status: "0",
                remark: "",
                drivefactor: "",
            },
            AddDateRules: {
                name: [
                    {
                        required: true,
                        message: "请输入名称",
                        trigger: "blur",
                    },
                ],
                connotation: [
                    {
                        required: true,
                        message: "请填写指标内涵",
                        trigger: "blur",
                    },
                ],
                drivefactor: [
                    {
                        required: true,
                        message: "请填写指标驱动系数",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    watch: {
        EditDateModel: {
            handler(newVal, oldVal) {
                this.echo();
            },
            deep: true,
        },
    },
    created() {
        this.echo();
    },
    methods: {
        echo() {
            if (this.EditDateModel.id) {
                this.AddDateModel.name = this.EditDateModel.name;
                this.AddDateModel.connotation = this.EditDateModel.connotation;
                this.AddDateModel.remark = this.EditDateModel.remark;
                this.AddDateModel.drivefactor = this.EditDateModel.drivefactor;
                this.AddDateModel.status = this.EditDateModel.status + "";
            }
        },
        addKeshi() {
            if (this.diaBtn == "新增") {
                // 新增
                this.addworkIn();
            } else if (this.diaBtn == "修改") {
                // 修改
                this.updateworkIn();
            }
        },
        clearadd() {
            this.AddDateModel = {
                name: "",
                connotation: "",
                status: "0",
                remark: "",
                drivefactor: "",
            };
            this.$refs.AddDateRef.resetFields();
            this.$emit("listworkIn");
        },
        addworkIn() {
            this.$refs.AddDateRef.validate(async (valid) => {
                if (valid) {
                    this.addDepClick = false;
                    this.addDepClickKing = true;
                    let data = _qs.stringify({
                        name: this.AddDateModel.name, //指标名称
                        connotation: this.AddDateModel.connotation, //指标内涵
                        status: this.AddDateModel.status, //	指标状态（0启用 1禁用）
                        remark: this.AddDateModel.remark, //	备注
                        drivefactor: this.AddDateModel.drivefactor, //指标驱动系数
                    });
                    let res;
                    if (this.listTit == "工作量指标管理") {
                        res = await this.$axios.addworkIn(data);
                    } else if (this.listTit == "专项奖励指标管理") {
                        res = await this.$axios.rewardAdd(data);
                    } else if (this.listTit == "单项补助及奖惩指标管理") {
                        res = await this.$axios.vidbsiAdd(data);
                    } else if (this.listTit == "个人绩效考核指标管理") {
                        res = await this.$axios.perforAdd(data);
                    } else if (this.listTit == "科研绩效指标管理") {
                        res = await this.$axios.scresAdd(data);
                    }
                    // console.log(res);
                    this.addDepClick = true;
                    this.addDepClickKing = false;
                    if (res.data.code == 401) {
                        this.$router.push("/login");
                    } else if (res.status == 200) {
                        this.clearadd();
                    } else {
                        this.$message({
                            message: res.data.msg,
                            type: "error",
                        });
                    }
                }
            });
        },
        updateworkIn() {
            this.$refs.AddDateRef.validate(async (valid) => {
                if (!valid) return;
                this.addDepClick = false;
                this.addDepClickKing = true;
                let data = _qs.stringify({
                    id: this.wardId, //工作量指标id
                    name: this.AddDateModel.name, //指标名称
                    connotation: this.AddDateModel.connotation, //指标内涵
                    status: this.AddDateModel.status, //	指标状态（0启用 1禁用）
                    remark: this.AddDateModel.remark, //	备注
                    drivefactor: this.AddDateModel.drivefactor, //指标驱动系数
                });
                let res;
                if (this.listTit == "工作量指标管理") {
                    res = await this.$axios.updateworkIn(data);
                } else if (this.listTit == "专项奖励指标管理") {
                    res = await this.$axios.rewardUpdate(data);
                } else if (this.listTit == "单项补助及奖惩指标管理") {
                    res = await this.$axios.vidbsiUpdate(data);
                } else if (this.listTit == "个人绩效考核指标管理") {
                    res = await this.$axios.perforUpdate(data);
                } else if (this.listTit == "科研绩效指标管理") {
                    res = await this.$axios.scresUpdate(data);
                }
                // console.log(res);
                this.addDepClick = true;
                this.addDepClickKing = false;
                if (res.data.code == 401) {
                    this.$router.push("/login");
                } else if (res.status == 200) {
                    this.clearadd();
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: "error",
                    });
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.btnCen {
    text-align: center;
}
</style>
